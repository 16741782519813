
import { defineComponent, ref, PropType, computed, onMounted } from "vue";
import Cloudfun, { Sorting, SortOrder} from "@cloudfun/core";

import type { Album } from "@/components/album/Main.vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import CfModal from "@/components/modal/Main.vue";
import CfButton from "@/components/button/Main.vue";

export default defineComponent({
  name: "ImportAlbumModal",
  components: {
    CfModal,
    CfButton,
    // CfInput,
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    ComboboxButton,
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const model = Cloudfun.current?.model;
    const albums = ref<Album[]>([]);
    const selectedItems = ref<Album>();
    const query = ref("");

    const filteredalbums = computed(() =>
      !query.value
        ? albums.value
        : albums.value.filter((album) =>
          album.Title
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.value.toLowerCase().replace(/\s+/g, ""))
        )
    );

    onMounted(async () => {
      try {
        albums.value = await model?.dispatch("album/query");
        // selectedItems.value.push(albums.value[0]);
      } catch {
        albums.value = [];
      }
    })

    const close = () => {
      emit('close')
    }

    const onImport = () => {
      emit("submit", selectedItems.value)
    }
    return {
      query,
      selectedItems,
      filteredalbums,
      onImport,
      close
    };
  },
});
