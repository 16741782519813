
import CloudFun, { ref, defineComponent, onMounted } from "@cloudfun/core";
import {
  Disclosure,
  DisclosureButton,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";
import {
  MenuIcon,
  XIcon,
} from "@heroicons/vue/outline";
import SkeletonLoading from "../skeleton/Main.vue";
import HistoryList from "@/components/history-list/Main.vue";
import CfButton from "@/components/button/Main.vue";
export default defineComponent({
  components: {
    Disclosure,
    DisclosureButton,
    MenuIcon,
    XIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    CfButton,
    HistoryList
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    isProject: {
      type: Boolean,
      default: false
    },
    queryData: {
      type: Object,
      requried: true
    }
  },
  emits: ["activeZoom", "resetViewer"],
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const router = CloudFun.current?.policy?.router;
    const open = ref(false);
    const history: any = ref([]);
    const isProject = ref(props.isProject)
    const detailInfo = ref(props.detail);

    const activeZoom = ()=> {
      CloudFun.send("info", "以滑鼠滾輪放大縮小");
      emit('activeZoom');
    }
    const resetViewer = () => {
      emit('resetViewer');
    }
    const saveDetailInfo = () => {
      if (detailInfo.value) model?.dispatch("picture/update", detailInfo.value);
    }
    const goBack = () => {
      router?.push(`${props.queryData?.path}?filter=${props.queryData?.params}`)
    }
    
    const showHistory = () => {
      if(history.value[0]?.PictureId !== detailInfo.value.Id) {
        model?.dispatch("picture/pictureRecord", {key: detailInfo.value.Id}).then((resp: any)=>{
        
        history.value = resp;
        console.log(history.value);
       })
      }
    }

    return {
      open,
      uploader: model?.state.batchUploader,
      detailInfo,
      history,
      goBack,
      saveDetailInfo,
      activeZoom,
      resetViewer,
      showHistory
    };
  },
});
