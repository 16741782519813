import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "flex items-start w-full px-4" }
const _hoisted_3 = { class: "text-base font-normal text-secondary mb-2" }
const _hoisted_4 = {
  key: 0,
  class: "text-base font-normal text-tertiary"
}
const _hoisted_5 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "px-2 py-4 hover:bg-grayscale-11 transition duration-300 ease-in-out block",
        onClick: ($event: any) => (_ctx.onItemClick(item.PictureId || item.Id))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(item?.PictureName || item?.Name), 1),
            (item?.PictureName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.InferenceResult || "無有效數值"), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ], 8, _hoisted_1))
    }), 128)),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_v_pagination, {
        modelValue: _ctx.paginationConfig.currentPage,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.paginationConfig.currentPage) = $event)),
          _ctx.updateHandler
        ],
        pages: _ctx.paginationConfig.pages,
        "range-size": 1,
        "active-color": "#1B1B1B"
      }, null, 8, ["modelValue", "pages", "onUpdate:modelValue"])
    ])
  ], 64))
}