import ChcekboxList from "./checkbox-list/Main.vue";
import SpinnerLoader from "./spinner-loader/Main.vue";
import FloatingVue from "floating-vue";
import Toast from "vue-toastification"
// import {
//     // Directives
//     VTooltip,
//     VClosePopper,
//     // Components
//     Dropdown,
//     Tooltip,
//     Menu
//   } from 'floating-vue'


export default (app: any): void => {
    app.component("SpinnerLoader", SpinnerLoader);

    app.use(FloatingVue);
    app.use(Toast);
    // app.use(Toast, { ... }); // 會有錯誤

    // app.directive('tooltip', VTooltip);
    // app.directive('close-popper', VClosePopper);

    // app.component('VDropdown', Dropdown);
    // app.component('VTooltip', Tooltip);
    // app.component('VMenu', Menu);
};
