
import CloudFun, { defineComponent, ref, onMounted, PropType } from "@cloudfun/core";
import type { Model } from "@/models/data"

import CfAutocomplete from "@/components/autocomplete/Main.vue";
import CfModal from "@/components/modal/Main.vue";
import CfButton from "@/components/button/Main.vue";
export default defineComponent({
  name: "NewProjectModal",
  components: {
    CfModal,
    CfAutocomplete,
    CfButton
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const selectedModel = ref();
    const models = ref<any>([]);
    const userId: any = CloudFun.current?.user.Id;


    const close = () => {
      emit('close')
    }

    const submit = () => {
      // console.log("USERID", userId)
      emit("submit", {
        Name: "Untitled",
        OperatorId: userId,
        Model: {
          RemoteId: selectedModel.value.RemoteId, 
          Name:selectedModel.value.Name 
        }
      })
    }

    onMounted(async () => {
      models.value = await model?.dispatch("serverModel/query").then((data)=>{
        let modelData = data.map((model)=>{
          return {
            Id: model.Id,
            Name: model.Name,
            Type: model.ModelType,
            RemoteId: model.RemoteId
          }
        }) 
        return modelData;
      });
    })

    return {
      models,
      selectedModel,
      close,
      submit
    };
  },
});
