import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    disabled: _ctx.disabled,
    class: _normalizeClass(["flex justify-center items-center text-center border rounded transition duration-300 ease-in-out pointer-events-auto", _ctx.style])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}