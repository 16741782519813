
import { defineComponent, ref, reactive, watch, onMounted, onUnmounted, computed } from "vue";
import Cloudfun, { Condition, Operator, Sorting, SortOrder } from "@cloudfun/core";
import { v1 as uuid } from "uuid";

import ProjectBar from "@/components/project-bar/Main.vue";
import FilterBar, { Filter } from "@/components/filter-bar/Main.vue";
import CfImageList, { ImageListOptions, Image } from "@/components/image-list/Main.vue";
import ImportAlbumModal from "@/components/modal/import-album/Main.vue";

import {
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";

export default defineComponent({
  components: {
    ProjectBar,
    FilterBar,
    CfImageList,
    ImportAlbumModal,
    Popover,
    PopoverButton,
    PopoverPanel,
  },
  setup() {
    const model = Cloudfun.current?.model;
    const router = Cloudfun.current?.policy?.router;
    const route = Cloudfun.route;
    const imageList = ref();
    const selectedItem = ref<Image | null>(null);
    const filter = ref<Filter | null>({ MinInferenceResult: 0, MaxInferenceResult: 1, Keyword: "", Sorting: { column:"Name", order: SortOrder.Ascending }});
    const isFiltered = ref(false);
    const loading = computed(() => imageList.value?.loading);
    const keyword: any = ref("");

    const imageListOptions: ImageListOptions = {
      promises: {
        query: (params) => {
          params.condition!.and("PictureRecord.ProjectId", Operator.Equal, router?.currentRoute.value.params.projectId);
          if (filter.value) {
            const { MinInferenceResult: min, MaxInferenceResult: max, Keyword, Sorting } = filter.value;
            if (!Number.isNaN(min)  && !Number.isNaN(max)) params.condition!.and("PictureRecord.InferenceResult", Operator.Equal, `${min}-${max}`);
            else if (!Number.isNaN(min)) params.condition!.and("PictureRecord.InferenceResult", Operator.GreaterThan, min);
            else if (!Number.isNaN(max)) params.condition!.and("PictureRecord.InferenceResult", Operator.LessThanOrEqual, max);
            if (Keyword) params.keyword = Keyword;
            if (Sorting) {
              // console.log("Sorting:", Sorting)
              // console.log("params.sortings:", params.sortings)
              params.sortings = [];
              params.sortings?.push(Sorting);
              // const current = params.sortings?.find(e => e.column === Sorting.column);
              // if (current) {
              //   current.order = Sorting.order;
              // } else {
              //   params.sortings?.push(Sorting);
              // }
            }
          } else { // default filter
            params.sortings = [new Sorting('Name', SortOrder.Ascending)];
          }
          params.pageSize = 20;
          return model!.dispatch("picture/query", params);
          
        }
      },

    }

    const onFilter = (data: any) => {
      console.log(filter.value)
      isFiltered.value = true;
      filter.value = data;
      keyword.value = filter.value?.Keyword;
      imageList.value.reload();
    }

    const onImageClick = ({ type, item }: { type: string, item: any }) => {
      if (type === "footer") {
        router?.push({
          path: '/detail/image',
          query: {
            pictureId: item.PictureId,
            projectId: item.ProjectId,
            filter: JSON.stringify(filter.value)
          }
        });
        return;
      }
      selectedItem.value = item;
    }
    const pjId: any = router?.currentRoute.value.params.projectId;

    const importModal = reactive({
      visible: false,
      show() {
        importModal.visible = true;
      },
      submit(items: { Id: number }) {
        try {
          model?.dispatch("picture/import", {
            projectId: parseInt(pjId),
            albumIds: items.Id
          }).then((data) => {
            const picIds = data.map(e => { return e.PictureId });
            importModal.visible = false;
            inference(picIds);

          }, failure => {
            Cloudfun.send("error", failure);
          });
        } catch {
          Cloudfun.send("error", "匯入失敗");
        }
      }
    })
    const onDelete =()=>{
      console.log(selectedItem.value)
      if(selectedItem.value) {
        model?.dispatch('picture/deleteImport', 
        { projectId: pjId, pictureId: selectedItem.value?.PictureId }).then(()=>{
          imageList.value.reload();
        })

      }
    }

    watch(() => model?.state["project"]["reloadUI"], value => {
      if (!value) return;
      imageList.value.reload();
      
      model!.state["project"]["reloadUI"] = false;
    })

    const handler = (event: any) => {
      const file = JSON.parse(event);
      const picIds = file?.response.payload.map(e => { return e.Id });
      const routeParam = router?.currentRoute.value.params["projectId"];
      if (pjId === routeParam) {
        inference(picIds)
      }
    }
    const inference = (picIds)=>{
      console.log("PicIds", picIds);
      // let picIds = imageList.value.data.map(e => { return e.PictureId });
      model?.dispatch("serverInference/inference", {
        projectId: parseInt(pjId),
        pictureIds: picIds
      }).finally(()=>{
        Cloudfun.send("success", "已開始進行運算!")
        model.state["project"]["reloadUI"] = true;

      })
    }
    onMounted(() => {
      Cloudfun.subscript('uploader:success', handler)
      if(route.query?.filter) {
        let filterData = JSON.parse(route.query?.filter as any)
        onFilter(filterData );
        filter.value = filterData;
        console.log("route.query?.filter", )
      }
    });
    onUnmounted(() => Cloudfun.unsubscript('uploader:success', handler));

    

    return {
      imageList,
      imageListOptions,
      filter,
      onFilter,
      onDelete,
      onImageClick,
      importModal,
      uploader: model?.state.batchUploader,
      loading,
      pjId,
      inference,
      isFiltered,
      keyword
    };
  },
  methods: {
    onInput(event: any) {
      if (!event?.target?.files?.length) return;
      if (!(event.target.files instanceof FileList)) return;
      const list = event.target.files as FileList;
      for (let i = 0; i < list.length; i++) {
        this.uploader.push({
          id: uuid(),
          name: list[i].name,
          size: list[i].size,
          mimeType: list[i].type,
          blob: list[i],
          url: URL.createObjectURL(list[i]),
          action: `${process.env.VUE_APP_API}/api/Files`,
          parameters: { projectId: this.$route.params["projectId"] },
          status: 'pending',
        });
      }
    }
  }
});
