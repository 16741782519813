import { createRouter, createWebHistory } from 'vue-router'
import AppLayout from './layouts/app-layout/Main.vue'
import ProjectLayout from './layouts/project-layout/Main.vue'
import ProjectDetail from "./views/home/project/detail/Main.vue";
import ProjectImage from "./views/home/project/image/Main.vue";
import AlbumDetail from "./views/home/album/detail/Main.vue";
import AlbumImage from "./views/home/album/image/Main.vue";


const routes = [
  {
    path: '/login',
    component: () => import("./views/Login.vue"),
  },
  {
    path: "/",
    component: AppLayout,
    children: [
      {
          path: '/:catchAll(.*)',
          component: () => import("./views/Error.vue"),
      },
      {
        path: "",
        component: () => import("./views/home/project/Main.vue"),
        meta: { rule: "Project"}
      },
      {
        path: "/project",
        component: () => import("./views/home/project/Main.vue"),
        // meta: { rule: "Project"}
      },
      {
        path: "/album",
        component: () => import("./views/home/album/Main.vue"),
        meta: { rule: "Album"}
      },
      {
        path: "/album/add-image",
        component: () => import("./views/home/album/add-image/Main.vue"),
        meta: { rule: "Album"}
      },
      {
        path: "/model",
        component: () => import("./views/home/model/Main.vue"),
        meta: { rule: "Model"}
      },
      {
        path: "/model/list",
        component: () => import("./views/home/model/grid-list/Main.vue"),
        meta: { rule: "Model"}
      },
      {
        path: "/quick-mode",
        component: () => import("./views/quick-mode/Main.vue"),
      },
      {
        path: "/quick-mode/import-img",
        component: () => import("./views/quick-mode/import-img/Main.vue"),
      },
      {
        path: "/model-library",
        component: () => import("./views/model-library/Main.vue"),
      },
      {
        path: "/user",
        component: () => import("./views/management/User.vue"),
        meta: { rule: "User"}
      },
      {
        path: "/role",
        component: () => import("./views/management/Role.vue"),
        meta: { rule: "Role"}
      },
      {
        path: "/action-log",
        component: () => import("./views/management/ActionLog.vue"),
        meta: { rule: "ActionLog"}
      },
      {
        path: "/information",
        component: () => import("./views/home/information/Main.vue"),
      }

    ],
  },
  {
    path: "/project",
    component: ProjectLayout,
    // meta: { rule: "Project"},
    children: [
      {
        path: ':projectId',
        name: 'project-detail', 
        component: ProjectDetail,
      },
      
    ],
  },
  {
    path: '/detail/image',
    name: 'project-image',
    component: ProjectImage,
  },
  {
    path: "/album",
    component: ProjectLayout,
    meta: { rule: "Album"},
    children: [
      {
        path: ':albumId',
        // name: 'detail', 
        component: AlbumDetail,
      },
    ],
  },
  {
    path: '/albumDetail/image/:pictureId',
    name: 'albumd-image',
    component: AlbumImage,
    props: true
  }
]

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) { return savedPosition || { left: 0, top: 0 } }
})
