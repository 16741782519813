
import { defineComponent, ref, PropType, watch } from "@cloudfun/core";

export default defineComponent({
  props: {
    history: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "DICOM"
    }
  },
  setup(props) {
    const historyData: any = ref([]);

    watch(() => props.history, value => {
      historyData.value = value;
      console.log("historyData", historyData)
    })

    return {
      historyData
    };
  }
});
