
import { ref, reactive, watch } from 'vue'
import {
  Popover,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/vue'
import { helper } from "@/utils/helper"
// import CfMultipleAutocomplete from "@/components/autocomplete/multiple/Main.vue";
import CfSearchBar from "@/components/search-bar/Main.vue";
import { Sorting, SortOrder } from '@cloudfun/core';

export interface Filter {
  Keyword: string;
  Sorting: Sorting;
}

const sortings = [
  { label: "Image Name", value: "Name" },
  { label: "Image CreatedTime", value: "CreatedTime" },
];

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    CfSearchBar,
    // CfMultipleAutocomplete
  },
  props: {
    count: Number,
    modelValue: null
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const isFiltered = ref(false);
    const filter = ref<any>(props.modelValue);
    // avoid reset button show 
    const defaultFilter = ref<Filter>({ Keyword: "", Sorting: { column:"Name", order: SortOrder.Ascending }});

    const onReset = () => {
      isFiltered.value = false;
      filter.value.Sorting = { column: sortings[0].value, order: SortOrder.Ascending };
    }
  
    const onChangeSortingColumn = (item: { label: string, value: string }) => {
      if (filter.value.Sorting.column === item.value) return;
      filter.value.Sorting.column = item.value;
      isFiltered.value = true;
    }

    const onChangeSortingOrder = (order: SortOrder) => {
      if (filter.value.Sorting.order === order) return;
      filter.value.Sorting.order = order;
      isFiltered.value = true;
    }

    watch(filter, value => {
      console.log(value);
      emit("submit", filter.value);
    }, { deep: true })
    watch(() => props.modelValue, (value) => {
      console.log("props.filterOption", props.modelValue);
      filter.value = value;
      if(!helper.areFiltersEqual(filter, defaultFilter)) isFiltered.value = true;
      console.log("defaultFilter:", helper.areFiltersEqual(filter, defaultFilter));
    }, { immediate: true })
    
    return {
      sortings,
      filter,
      isFiltered,
      onReset,
      onChangeSortingColumn,
      onChangeSortingOrder,
    }
  },
}
