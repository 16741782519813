
import Cloudfun, { defineComponent, ref, reactive, onMounted } from "@cloudfun/core";
import { v1 as uuid } from "uuid";

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";
import {
  BellIcon,
  MenuIcon,
  RefreshIcon,
  PlayIcon,
  XIcon,
  PauseIcon,
  CheckIcon
  // ChevronDownIcon,
} from "@heroicons/vue/outline";
import { ContentLoader } from "vue-content-loader";
import MyAccountModal from "@/components/modal/my-account/Main.vue";
import CfButton from "@/components/button/Main.vue";
import NewProjectModal from "@/components/modal/new-project/Main.vue";
import NewAlbumModal from "@/components/modal/new-album/Main.vue";
import { mapActions } from 'vuex';
import { POSITION, useToast } from "vue-toastification";
// import FileUpload, { VueUploadItem } from 'vue-upload-component';

export default defineComponent({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    RefreshIcon,
    PlayIcon,
    XIcon,
    PauseIcon,
    CheckIcon,
    // ChevronDownIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    MyAccountModal,
    NewProjectModal,
    NewAlbumModal,
    CfButton,
    ContentLoader,
    // FileUpload
  },
  props: {
    loading: Boolean,
  },
  emits: ["activeLoad"],
  setup(props, {emit}) {
    const model = Cloudfun.current?.model;
    
    const router = Cloudfun.current?.policy?.router;
    const systemPermision = Cloudfun.current?.user.Permissions.includes('User');
    const userInfo = ref(Cloudfun.user);
    const uploader = model?.state.batchUploader;
    const navigation = [
      { name: "HOME", href: "/", current: true, disabled: false, },
      // { name: "QUICK MODE", href: "/quick-mode", current: false, disabled: false, },
      // { name: "MODEL LIBRARY", href: "/model-library", current: false, disabled: false, },
    ];

    const uploadNav = [
      { name: "New Project", href: "/project", type: 'project', divider: false, tooltip: false, },
      { name: "New Album", href: "/album", type: 'album', divider: true, tooltip: false, },
      // { name: "DICOM Files Upload", href: "#", divider: false, tooltip: false, },
      // { name: "IMAGE Files Upload", href: "#", divider: false, tooltip: true, },
      // { name: "Model Upload", href: "/model", divider: false, tooltip: false, },
    ];

    const modals = reactive({
      projectVisible: false,
      albumVisible: false,
      accountVisible: false,
      show(type: "account" | "project" | "album") {
        modals[type + "Visible"] = true;
      },
      hide(type: "account" | "project" | "album") {
        modals[type + "Visible"] = false;
      },
      async submit(type: "project" | "album", item) {
        try {
          const temp = await model?.dispatch(type + "/insert", item);
          modals.hide(type);
          if(type !== "project") router?.push(`/${type}/` + temp.Id);
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    });
    const updateUser = (data) =>{
      console.log("Update User:", data)
      userInfo.value.Email = data.Email;
      userInfo.value.Name = data.Name;
    }
    const uploadFiles: any = ref([]);
    const uploadModel = (event: any) => {
      emit('activeLoad', true);
      // console.log(event?.target?.files[0])
      // if (!event?.target?.files?.length) return;
      // if (!(event.target.files instanceof FileList)) return;
      const formData = new FormData();
      formData.append("file", event?.target?.files[0])
      model?.dispatch('serverModel/upload', {formData}).then(() => {
        useToast().success(
          `上傳成功!`
          , { position: POSITION.TOP_CENTER }
        )
      },(error)=>{
        useToast().error(
          `上傳失敗!`
          , { position: POSITION.TOP_CENTER }
        )
      }).finally(()=>{
        emit('activeLoad', false);
      });
    }

    return {
      navigation,
      userInfo,
      uploadNav,
      updateUser,
      uploadFiles,
      uploadModel,
      modals,
      uploader,
      systemPermision
    };
  },
  methods: {
    ...mapActions(["logout"]),
    onInput(event: any) {
      if (!event?.target?.files?.length) return;
      if (!(event.target.files instanceof FileList)) return;
      const list = event.target.files as FileList;
      for (let i = 0; i < list.length; i++) {
        this.uploader.push({
          id: uuid(),
          name: list[i].name,
          size: list[i].size,
          mimeType: list[i].type,
          blob: list[i],
          url: URL.createObjectURL(list[i]),
          action: `${process.env.VUE_APP_API}/api/Files`,
          status: 'pending',
        });
      }
    },
    signOut() {
      // console.log(this.systemPermision)
      this.logout().finally(() => {
        this.$router.push("/login");
      })
    }
  }
});
