import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-start w-full px-4" }
const _hoisted_2 = { class: "text-base font-normal text-secondary mb-2" }
const _hoisted_3 = { class: "text-base font-normal text-tertiary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.history as any), (item) => {
    return (_openBlock(), _createBlock(_component_router_link, {
      key: item.ProjectId,
      class: "px-2 py-4 hover:bg-grayscale-11 transition duration-300 ease-in-out block",
      to: { path:'/project/'+item.ProjectId },
      target: "_blank"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, " Project- " + _toDisplayString(item.Project?.Name), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(item.InferenceResult), 1)
          ])
        ])
      ]),
      _: 2
    }, 1032, ["to"]))
  }), 128))
}