
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import CfModal from "@/components/modal/Main.vue";
import CfButton from "@/components/button/Main.vue";
import CfInput from "@/components/input/Main.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

export default defineComponent({
  components: {
    CfModal,
    CfButton,
    CfInput,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  emits: ["close", "updateUser"],
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    // const MyAccountModal = ref<string>("");
    const userInfo = ref({
      Id: CloudFun.user.Id,
      Name: CloudFun.user.Name,
      Email: CloudFun.user?.Email
    })
  
    const newPassword = ref('');
    const oldPassword = ref('');
    const infoMsg = ref('');
    const oldErrorMsg = ref('');
    const newErrorMsg = ref('');
    const changePassword = () =>  {
      oldErrorMsg.value = "";
      newErrorMsg.value = "";
      const regex = new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)");
              
      if(oldPassword.value) {
        if (!regex.test(oldPassword.value)) {
          oldErrorMsg.value = "須8碼以上含大小寫英文、數字";
          return;
        }
        if(newPassword.value) {
          if (!regex.test(newPassword.value)) {
            newErrorMsg.value = "須8碼以上含大小寫英文、數字";
            return;
          }
              
          let data = {
            key: CloudFun.user.Id,
            password: newPassword.value,
            oldPassword: oldPassword.value
          }
          model?.dispatch('user/changePassword', data).then((data) => {
            if(data.message.includes('錯誤')) {
              oldErrorMsg.value = data.message;
            }
            else {
              CloudFun.send("info", "修改密碼成功!");
              emit('close')
            }
          },
          failure => {
            console.log('error')
          })
        }
        else {
          newErrorMsg.value = "欄位不得空白!";
        }
        
      }
      else {
        oldErrorMsg.value = "欄位不得空白!";
      }
    }
    const upedateInfo = () => {
      let userData = userInfo.value;
      if(userData.Name && userData.Email) {
        const regex = new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$");
        if (!regex.test(userData.Email)) {
          infoMsg.value = "Email 格式錯誤";
          return;
        }
        model?.dispatch('user/profile', userData).then((data) => {
          console.log(data);
            if(data.message.includes('錯誤')) {
              infoMsg.value = data.message;
            }
            else {
              CloudFun.send("info", "更新資料成功!");
              emit('updateUser', userData)
              emit('close')
            }
        })
      }
      else {
        infoMsg.value = "欄位不得空白!";
      }
    }

    const close = () => {
      emit('close')
    }

    return {
      // MyAccountModal,
      newPassword,
      oldPassword,
      infoMsg,
      oldErrorMsg,
      newErrorMsg,
      userInfo,
      changePassword,
      upedateInfo,
      close
    };
  },
  methods: {
    
  }
});
