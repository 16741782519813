
import { defineComponent, ref, PropType, watch } from "@cloudfun/core";

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    style: String,
    type: String,
    modelValue: String,
    placeholder: String,
  },
  setup(props) {
    const inputValue = ref("");
    watch(() => props.modelValue, (value) => {
      console.log(value)
      inputValue.value = value || "";
    }, { immediate: true })
    return {
      inputValue
    };
  },
  methods: {
    updateInput(event) {
      this.$emit("update:modelValue", event.target.value);
    }
  },
});
