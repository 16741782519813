
import { defineComponent, ref } from "vue";

import CfModal from "@/components/modal/Main.vue";
import CfButton from "@/components/button/Main.vue";

export default defineComponent({
  name: "NewAlbumModal",
  components: {
    CfModal,
    CfButton
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const title = ref("Untitled");

    const close = () => {
      emit('close')
    }

    const submit = () => {
      if (!title.value) return;
      emit("submit", {
        Title: title.value
      })
    }

    return {
      title,
      close,
      submit
    };
  },
});
