
import { defineComponent, ref, reactive, watch, onMounted, onUnmounted, computed } from "vue";
import Cloudfun, { Condition, Operator, Sorting, SortOrder } from "@cloudfun/core";
import { v1 as uuid } from "uuid";

import AlbumBar from "@/components/album-bar/Main.vue";
import FilterBar, { Filter } from "@/components/album-filter-bar/Main.vue";
import CfImageList, { ImageListOptions, Image } from "@/components/picture-list/Main.vue";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";
import ImportAlbumModal from "@/components/modal/import-album/Main.vue";

export default defineComponent({
  components: {
    AlbumBar,
    FilterBar,
    CfImageList,
    ImportAlbumModal,
    Popover,
    PopoverButton,
    PopoverPanel,
  },
  setup() {
    const model = Cloudfun.current?.model;
    const router = Cloudfun.current?.policy?.router;
    const route = Cloudfun.route;
    const imageList = ref();
    const selectedItem = ref<Image | null>(null);
    const filter = ref<Filter | null>({ Keyword: "", Sorting: { column:"Name", order: SortOrder.Ascending }});
    const isFiltered = ref(false);
    const loading = computed(() => imageList.value?.loading);
    const keyword: any = ref("");

    const imageListOptions: ImageListOptions = {
      promises: {
        query: (params) => {
          const albumId = router?.currentRoute.value.params.albumId;
          params.condition!.and("AlbumId", Operator.Equal, albumId);
          if (filter.value) {
            const { Keyword, Sorting } = filter.value;
            if (Keyword) params.condition!.and("Name", Operator.Contains, Keyword);
            if (Sorting) {
              params.sortings = [];
              params.sortings?.push(Sorting);
            }
          } else { // default filter
            params.sortings = [ new Sorting('Name', SortOrder.Ascending)];
          }
          params.pageSize = 20;
          return model!.dispatch("picture/albumPictures", params)
        }
      }
    }

    const onFilter = (data: any) => {
      isFiltered.value = true;
      filter.value = data;
      keyword.value = filter.value?.Keyword
      imageList.value.reload();
    }

    const onImageClick = ({ type, item }: { type: string, item: Image }) => {
      if (type === "footer") {
        router?.push({
          name: `albumd-image`,
          params: {
            pictureId: item.Id
          },
          query: {
            albumId: router?.currentRoute.value.params.albumId,
            filter: JSON.stringify(filter.value)
          }
        });
        return;
      }
      selectedItem.value = item;
      console.log(selectedItem);
    }

    const importModal = reactive({
      visible: false,
      submit(items: { Id: number }[]) {
        try {
          model?.dispatch("picture/import", {
            albumId: router?.currentRoute.value.params.albumId,
            ids: items.map(e => e.Id)
          }).then(() => {
            model.state["album"]["reloadUI"] = true;
            importModal.visible = false;
          });
        } catch {
          Cloudfun.send("error", "匯入失敗");
        }
      }
    })

    const onDelete =()=>{
      if(selectedItem.value) {
        model?.dispatch('picture/delete', selectedItem.value?.Id).then(()=>{
          imageList.value.reload();
        })

      }
    }
      
    watch(() => model?.state["album"]["reloadUI"], value => {
      if (!value) return;
      imageList.value.reload();
      model!.state["album"]["reloadUI"] = false;
    })
    
    const handler = (event: any) => {
      const file = JSON.parse(event);
      const projectId = file?.parameters["albumId"].toString();
      const routeParam = router?.currentRoute.value.params["albumId"];
      if (projectId === routeParam) {
        imageList.value.reload();
      }
    }

    onMounted(() => {
      Cloudfun.subscript('uploader:success', handler)
      if(route.query?.filter) {
        onFilter(JSON.parse(route.query?.filter as any));
        console.log("route.query?.filter", )
      }
    });
    onUnmounted(() => Cloudfun.unsubscript('uploader:success', handler));


    return {
      imageList,
      imageListOptions,
      filter,
      onFilter,
      onImageClick,
      importModal,
      uploader: model?.state.batchUploader,
      loading,
      selectedItem,
      onDelete,
      keyword,
      isFiltered
    };
  },
  methods: {
    onInput(event: any) {
      if (!event?.target?.files?.length) return;
      if (!(event.target.files instanceof FileList)) return;
      const list = event.target.files as FileList;
      for (let i = 0; i < list.length; i++) {
        this.uploader.push({
          id: uuid(),
          name: list[i].name,
          size: list[i].size,
          mimeType: list[i].type,
          blob: list[i],
          url: URL.createObjectURL(list[i]),
          action: `${process.env.VUE_APP_API}/api/Files`,
          parameters: { albumId: this.$route.params["albumId"] },
          status: 'pending'
        });
      }
    }
  }
});
