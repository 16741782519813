
import { toRefs } from "vue";
import { defineComponent, ref, PropType } from "@cloudfun/core";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Dialog,
  DialogOverlay,
  DialogTitle,
  // TransitionChild,
  // TransitionRoot,
} from "@headlessui/vue";

export default defineComponent({
  components: {
    Dialog,
    DialogTitle,
    DialogOverlay,
    // TransitionChild,
    // TransitionRoot,
  },
  props: {
    title: String,
    show: Boolean,
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { show: isOpen } = toRefs(props);
    return {
      isOpen,
      onClose() {
        isOpen.value = false;
        emit("close");
      },
    };
  },
});
