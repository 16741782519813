
import { defineComponent, ref, PropType, computed, onMounted } from "@cloudfun/core";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";

export default defineComponent({
  components: {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    ComboboxButton,
  },
  props: {
    optionItems: {
      type: Array
    },
    modelValue: { type: [String, Array] as PropType<string | string[]> }
  },
  setup(props) {
    const albums = ref<any>(props.optionItems)
    let selected = ref()
    // albums[0]
    let query = ref("");

    let filteredItems = computed(() =>
      query.value === ""
        ? albums.value
        : albums.value.filter((item) =>
          item.Name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.value.toLowerCase().replace(/\s+/g, ""))
        )
    );
    return {
      query,
      albums,
      selected,
      filteredItems,
    };
  },
    watch: {
    selected (current) {
      console.log('update', this.selected);
      this.$emit('update:modelValue', this.selected)
    }
  },
  methods: {},
});
