import { Model } from '@cloudfun/core'
import actionLog from './action-log'
import album from './album'
import configuration from './configuration'
import trainingModel from './model'
import picture from './picture'
import pictureAlbum from './picture-album'
import project from './project'
import user from './user'
import role from './role'
import batchUploader from './batch-uploader'
import serverInference from './server-inference';
import serverModel from './server-model';

const model = new Model('muen-xray', {
  actionLog,
  album,
  configuration,
  model: trainingModel,
  picture,
  pictureAlbum,
  project,
  user,
  role,
  batchUploader,
  serverInference,
  serverModel
})

const backendServer = process.env.VUE_APP_API;
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.onLogin = data => model.clients.unauthorized.post("System/Login", data);
model.onLogout = () => model.clients.authorized.post("System/Logout");
model.onReloadUser = () => model.clients.authorized.post("System/CurrentUser");
model.onReloadEnums = () =>
  model.clients.unauthorized.get("System/Enumerations");
model.onLog = message => model.clients.unauthorized.post("System/Log", message);

export default model;
