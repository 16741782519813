
import { defineComponent, ref, PropType } from "@cloudfun/core";
export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: String,
    style: String,
  },
  setup(props) {
    return {};
  },
  components: {},
});
