
/* eslint-disable */
import Cloudfun, { defineComponent, ref, PropType, Condition, Operator, Sorting, SortOrder,  onMounted, watch } from "@cloudfun/core";
import VPagination from "@hennge/vue3-pagination";

export interface Image {
  Id: number;
  Name?: string;
  Uri: string;
  ThumbnailUri: string;
  InferenceResult: number;
  PictureId: number;
  CreatedTime: string;
}

export interface ImageListOptions {
  /** 非同步設定 */
  promises?: {
    /** 非同步查詢 */
    query?: (params: {
      page: number;
      pageSize: number;
      keyword?: string;
      sortings?: Sorting[];
      condition?: Condition;
    }) => Promise<{ data: Image[]; pageCount: number; }>;
  };
}

export default defineComponent({
  props: {
    /** 可提供的承諾 */
    promises: {
      type: Object as PropType<{
        query?: (params: {
          page: number;
          pageSize: number;
          keyword?: string;
          sortings?: Sorting[];
          condition?: Condition;
        }) => Promise<{ data: Image[]; pageCount: number; }>;
        // save?: (params: {
        //   insertRows?: any[];
        //   updateRows?: any[];
        //   deleteRows?: any[];
        // }) => Promise<void>;
      }>,
    },
    keyword: {
      type: String
    }
  },
  emits: ["itemClick"],
  setup(props, { emit }) {
    const data = ref<any[]>([]);
    const sortings = ref<Sorting[]>([]);
    const loading = ref(false);
    const keyword: any = ref(null);
    const paginationConfig = {
      currentPage: 1,
      pages: 1
    };
    const loadData = () => {
      if (props.promises?.query) {
        loading.value = true;
        // const queryPagination = props.promises?.query;
        props.promises.query({ page: paginationConfig.currentPage, pageSize: 20, condition: new Condition(), sortings: sortings.value }).then(res => {
          data.value = res.data;
          paginationConfig.pages = res.pageCount;
        }).finally(() => {
          loading.value = false;
        });
      }
    }
    onMounted(() => {
      // console.log('Load DAataaaa!')
      loadData();
    })
    const onItemClick = (pictureId) =>{

      emit("itemClick", { pictureId });
    }
    const updateHandler = (e:any)=>{
      paginationConfig.currentPage = e;
      loadData();
    }
    return {
      data,
      paginationConfig,
      updateHandler,
      onItemClick
    };
  },
  methods: {
    toProject(id: any) {
      this.$router.push('/project/' + id);
    }
  }
});
