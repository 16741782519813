
/* eslint-disable */
import { defineComponent, PropType, ref, onMounted, watch } from "@cloudfun/core";
import Cloudfun, { Condition, Operator, Sorting, SortOrder } from "@cloudfun/core";
import { ContentLoader } from "vue-content-loader";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export interface Image {
  Id: number;
  Name?: string;
  Uri: string;
  ThumbnailUri: string;
  InferenceResult: number;
  PictureId: number;
  CreatedTime: string;
}

export interface ImageListOptions {
  /** 非同步設定 */
  promises?: {
    /** 非同步查詢 */
    query?: (params: {
      page: number;
      pageSize: number;
      keyword?: string;
      sortings?: Sorting[];
      condition?: Condition;
    }) => Promise<{ data: Image[]; pageCount: number; }>;
  };
}

export default defineComponent({
  name: "CfImageList",
  components: {
    ContentLoader,
    VPagination
  },
  props: {
    /** 可提供的承諾 */
    promises: {
      type: Object as PropType<{
        query?: (params: {
          page: number;
          pageSize: number;
          keyword?: string;
          sortings?: Sorting[];
          condition?: Condition;
        }) => Promise<{ data: Image[]; pageCount: number; }>;
        // save?: (params: {
        //   insertRows?: any[];
        //   updateRows?: any[];
        //   deleteRows?: any[];
        // }) => Promise<void>;
      }>,
    },
    pjId: {
      type: String
    },
    keyword: {
      type: String
    }
  },
  emits: ["itemClick"],
  setup(props, { emit }) {
    // const selectedRow = ref(-1);
    const data = ref<any[]>([]);
    const sortings = ref<Sorting[]>([]);
    const loading = ref(false);
    const paginationConfig = {
      currentPage: 1,
      pages: 1
    };
    const keyword: any = ref(null);

    const loadData = () => {
      if (props.promises?.query && props.pjId) {
        loading.value = true;
        let pjId = parseInt(props.pjId)
        const queryPagination = props.promises?.query;
        props.promises.query({ page: paginationConfig.currentPage, pageSize: 20, condition: new Condition(), sortings: sortings.value }).then(res => {
          data.value = res.data;
          paginationConfig.pages = res.pageCount;
          if(keyword) {
            console.log(keyword)
            var regEx = new RegExp(keyword.value, "i");
            data.value.forEach((e: any)=>{
              e.PictureName = e.PictureName.replace(regEx, "<b style='color: yellow;'>$&</b>")
            })
          }
          
        }).finally(() => {
          loading.value = false;
        });
      }
    }

    watch(() => props.keyword, value => keyword.value = value)
    onMounted(() => {
      loadData();
    })
    const current: any = ref(null);
    const page: any = ref(1);
    const onItemClick = (type: "body" | "footer", item: Image, index: number) => {
      console.log('Item clicked', item)
      current.value = index;
      emit("itemClick", { type, item, index });
    }
    const updateHandler = (e:any)=>{
      paginationConfig.currentPage = e;
      loadData();
    }

    return {
      data,
      defaultImage: require("@/assets/images/placeholder/placeholder.svg"),
      loading,
      loadData,
      onItemClick,
      current,
      paginationConfig,
      updateHandler
    };
  },
  methods: {
    reload() {
      this.loadData();
    }
  }
});
