
// import
import { ref, defineComponent, onMounted } from "vue";
import dwv from 'dwv'
import { ContentLoader } from "vue-content-loader";


export default defineComponent({
  components: {
    ContentLoader
  },
   props: {
    source: {
      type: String,
      required: true
    }
  },
  emits: ["dicomInfo"],
  setup(props, { emit }) {
    let dwvApp: any | null =  null;
    const loading = ref(true);
    let tools = {
        Scroll: {},
        ZoomAndPan: {},
        WindowLevel: {},
        Draw: {
          options: ['Ruler'],
          type: 'factory',
          events: ['drawcreate', 'drawchange', 'drawmove', 'drawdelete']
        }
      }, 
      selectedTool = 'Select Tool',
      loadProgress = 0,
      dataLoaded = false,
      metaData = null,
      showDicomTags = false,
      dropboxDivId = 'dropBox',
      dropboxClassName = 'dropBox',
      borderClassName = 'dropBoxBorder',
      hoverClassName = 'hover';
    const zoomActive = () => {
      dwvApp.setTool('ZoomAndPan');
    }
    const onReset = () => {
      dwvApp.resetDisplay()
      // console.log(dwvApp.getMetaData(0))
      
    }
    onMounted(()=>{
        if (props.source) {
            // Image decoders (for web workers)
            dwv.image.decoderScripts = {
                "jpeg2000": "/decoders/pdfjs/decode-jpeg2000.js",
                "jpeg-lossless": "/decoders/rii-mango/decode-jpegloss.js",
                "jpeg-baseline": "/decoders/pdfjs/decode-jpegbaseline.js"
            };
          dwvApp = new dwv.App()
          dwvApp.init({
              dataViewConfigs: {'*': [{divId: 'layerGroup0'}]},
              tools: tools
          })
          dwvApp.loadURLs([props.source]);
          dwvApp.addEventListener('load', function () {
            console.log('load!');
            loading.value = false;
            dwvApp.setTool('Scroll');
            // console.log("Metadata:", dwvApp.getMetaData(0))
            let Metadata = dwvApp.getMetaData(0);
            let dicomInfo: any = [];
            if(Metadata?.PatientName) {
              dicomInfo = [{ Name: "PatientName", Value: Metadata.PatientName.value },
              { Name: "PatientSex", Value: Metadata.PatientSex.value},
              { Name: "PatientBirthDate", Value: Metadata.PatientBirthDate.value },
              { Name: "StudyDate", Value: Metadata.StudyDate.value },
              { Name: "StudyTime", Value: Metadata.StudyTime.value }]
            }
            console.log('dicomInfo', dicomInfo);

            emit('dicomInfo', dicomInfo)
            // dwvApp.setDrawShape(tools.Draw.options[0]);
          });
         
        }
    })


    return {
      versions: {
        dwv: dwv.getVersion()
      },
      loading,
      tools,
      selectedTool,
      loadProgress,
      dataLoaded,
      metaData,
      showDicomTags,
      dropboxDivId,
      dropboxClassName,
      borderClassName,
      hoverClassName,
      zoomActive,
      onReset
      
    }
  },
 
})
