
import { defineComponent, ref, watch, nextTick, onMounted } from "vue";

export default defineComponent({
  props: {
    modelValue: String,
    placeholder: {
      type: String,
      required: true,
      default: "Search",
    }
  },
  emits: ["update:modelValue", "submit", "reset"],
  setup(props, { emit }) {
    const current = ref("");
    const searchRef: any = ref(null)
    watch(() => props.modelValue, value => current.value = value || "");

    const search = () => {
      emit("submit", current.value);
    }
    onMounted(() => {
      // console.log(searchRef.value)
      searchRef.value.addEventListener("search", function(event) {
        emit("submit", current.value);
      });
    })
    return {
      current,
      search,
      searchRef
    };
  }
});
