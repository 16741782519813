
import { ref, reactive, watch } from 'vue'
import {
  Popover,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/vue'
import { helper } from "@/utils/helper"
// import CfMultipleAutocomplete from "@/components/autocomplete/multiple/Main.vue";
import CfSearchBar from "@/components/search-bar/Main.vue";
import VueSlider from "vue-slider-component";
import { Sorting, SortOrder } from '@cloudfun/core';

export interface Filter {
  MinInferenceResult: number;
  MaxInferenceResult: number;
  Keyword: string;
  Sorting: Sorting;
  // Tags: number[];
}

const sortings = [
  { label: "Image Name", value: "Name" },
  { label: "Inference Order", value: "CreatedTime" },
  { label: "Inference Result", value: "InferenceResult" }
];

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    CfSearchBar,
    VueSlider,
    // CfMultipleAutocomplete
  },
  props: {
    count: Number,
    modelValue: null
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const slider = ref<any>(null);
    const isFiltered = ref(false);
    const filter = ref<Filter>(props.modelValue);
    // avoid reset button show 
    const defaultFilter = ref<Filter>({ MinInferenceResult: 0, MaxInferenceResult: 1, Keyword: "", Sorting: { column:"Name", order: SortOrder.Ascending }});
    const inferenceResults = reactive({
      range: [0, 1],
      min: 0,
      max: 1,
      apply() {
        // console.log(inferenceResults.range)
        if(typeof(inferenceResults.range[0]) !== 'number' || typeof(inferenceResults.range[1]) !== 'number') return alert("數值不能為空值");
        isFiltered.value = true;
        filter.value.MinInferenceResult = inferenceResults.range[0];
        filter.value.MaxInferenceResult = inferenceResults.range[1];
      },
      reset() {
        slider.value.setValue([0,1]);
      }
    });

    const onReset = () => {
      isFiltered.value = false;
      inferenceResults.range = [0, 1]
      filter.value.Sorting = { column: sortings[0].value, order: SortOrder.Ascending };
      filter.value.MinInferenceResult = 0;
      filter.value.MaxInferenceResult = 1;
      // console.log(sortings[0].value)
    }
  
    const onChangeSortingColumn = (item: { label: string, value: string }) => {
      if (filter.value.Sorting.column === item.value) return;
      filter.value.Sorting.column = item.value;
      isFiltered.value = true;
    }

    const onChangeSortingOrder = (order: SortOrder) => {
      if (filter.value.Sorting.order === order) return;
      filter.value.Sorting.order = order;
      isFiltered.value = true;
    }

    watch(filter, value => {
      // console.log("Change");
      emit("submit", filter.value);
    }, { deep: true })
    watch(() => props.modelValue, (value) => {
      console.log("props.filterOption", props.modelValue);
      filter.value = value;
      if(!helper.areFiltersEqual(filter, defaultFilter)) isFiltered.value = true;
      console.log("defaultFilter:", helper.areFiltersEqual(filter, defaultFilter))
      
    }, { immediate: true })
    

    return {
      value: [0, 50],
      inferenceResults,
      sortings,
      filter,
      isFiltered,
      slider,
      onReset,
      onChangeSortingColumn,
      onChangeSortingOrder,
    }
  },
}
