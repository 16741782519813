import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled", "placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: _ctx.type,
    disabled: _ctx.disabled,
    placeholder: _ctx.placeholder,
    class: _normalizeClass(["flex justify-center items-center text-center text-base text-normal text-secondary border-grayscale-10 placeholder-tertiary bg-transparent hover:border-tertiary hover:placeholder-tertiary transition duration-300 ease-in-out focus-status", _ctx.style]),
    value: _ctx.modelValue,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateInput && _ctx.updateInput(...args)))
  }, null, 42, _hoisted_1))
}