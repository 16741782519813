
import {
  Disclosure,
} from "@headlessui/vue";

const navigation = [
  { name: "About us", href: "#", current: false, disabled: true, },
  { name: "Feedback", href: "#", current: false, disabled: true, },
];

export default {
  name: "BottomBar",
  components: {
    Disclosure,
  },
  setup() {
    return {
      navigation,
    };
  },
};
