
/* eslint-disable */
import Cloudfun, { ref, defineComponent, Condition, Operator, Sorting, SortOrder } from "@cloudfun/core";
import DicomViewer from "@/components/dicom-viewer/Main.vue";
import SideImageList, { ImageListOptions, Image } from "@/components/side-image-list/Main.vue";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";
import {
  BellIcon,
  MenuIcon,
  XIcon,
  // ChevronDownIcon,
} from "@heroicons/vue/outline";
import DetailBar from "@/components/detail-bar/Main.vue";
import HistoryList from "@/components/history-list/Main.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    DetailBar,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    DicomViewer,
    MenuIcon,
    XIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    Carousel,
    Slide,
    Navigation,
    SideImageList
  },
  setup() {
    const model = Cloudfun.current?.model;
    const router = Cloudfun.current?.policy?.router;
    const route = Cloudfun.route;
    const imageList = ref();
    const picture = ref<any>();
    const previousPicture = ref<any>();
    const nextPicture = ref<any>();
    const dicomViwer = ref();
    const infoData: any = ref([]);
    const filterData: any = ref({});
    const keyword: any = ref("");
    const imageListOptions: ImageListOptions = {
      promises: {
        query: (params) => {
          params.condition!.and("AlbumId", Operator.Equal, route.query["albumId"]);
          if (filterData.value) {
            const { Keyword, Sorting } = filterData.value;
            if (Keyword) params.condition!.and("Name", Operator.Contains, Keyword);
            if (Sorting) {
              params.sortings = [];
              params.sortings?.push(Sorting);
            }
          } else { // default filter
            params.sortings = [ new Sorting('Name', SortOrder.Ascending)];
          }
          params.pageSize = 100;
          return model!.dispatch("picture/albumPictures", params)
        }
      }
    }
    
    const showInfo = (data: any)=>{
      infoData.value = data;
    }
    return {
      imageList,
      imageListOptions,
      picture,
      previousPicture,
      filterData,
      nextPicture,
      dicomViwer,
      infoData,
      keyword,
      showInfo
    };
  },
  // 載入時在 body tag 加上 class
  mounted() {
    // console.log("$route:", this.$route)
    document.body.classList.add("for-detail");
    this.changePicture(this.$route.params["pictureId"]);
    this.filterData.params = this.$route.query?.filter;
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any)=>{
      vm.filterData.path = from.path;
    });
  },
  destroyed() {
    document.body.classList.remove("for-detail");
  },
  methods: {
    async changePicture(id: any) {
      this.picture = await this.$model.dispatch("picture/find", id)
      let filter =  JSON.parse(this.$route.query?.filter as any),
          keyword = filter.Keyword || "",
          sortings = filter.Sorting || [new Sorting('Name', SortOrder.Ascending)];
      // this.history = await this.$model.dispatch("picture/pictureRecord", {key: this.picture.Id})
  
      try {
        this.previousPicture = await this.$model.dispatch("picture/previous", { keyword: keyword, sortings: sortings, id: this.picture.Id, projectId: null, albumId: this.picture.AlbumId });
      } catch {
        this.previousPicture = undefined;
      }
      try {
        this.nextPicture = await this.$model.dispatch("picture/next", { keyword: keyword, sortings: sortings, id: this.picture.Id, projectId: null, albumId: this.picture.AlbumId });
      } catch { 
        this.nextPicture = undefined;
      }
    }
  },
});
