
import { ref, defineComponent, reactive, onMounted } from "vue";
import Cloudfun from "@cloudfun/core";
import type { Album } from "@/models/data";
import { v1 as uuid } from "uuid";
import CfButton from "@/components/button/Main.vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";
import {
  MenuIcon,
  RefreshIcon,
  PlayIcon,
  XIcon,
  PauseIcon,
  CheckIcon
} from "@heroicons/vue/outline";
import { ContentLoader } from "vue-content-loader";
import RenameModal from "@/components/modal/rename/Main.vue";
import MyAccountModal from "@/components/modal/my-account/Main.vue";
import ImportAlbumModal from "@/components/modal/import-album/Main.vue";
import { mapActions } from 'vuex';

const tooltipContent =
  "The choosen model is trained <b class='font-bold'>IMAGE</b> dataset. <br />Using <b class='font-bold'>DICOM</b> format may affect the inference result.";

export default defineComponent({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    RefreshIcon,
    PlayIcon,
    XIcon,
    PauseIcon,
    CheckIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    MyAccountModal,
    RenameModal,
    CfButton,
    ImportAlbumModal,
    ContentLoader
  },
  props: {
    loading: Boolean,
  },
  setup() {
    const model = Cloudfun.current?.model;
    const router = Cloudfun.current?.policy?.router;
    const modalOpen = ref(false);

    function openModal() {
      // console.log('OPEN')
      modalOpen.value = true
    }

    const modalSharingGroupOpen = ref(false);

    function openSharingGroupModal() {
      // console.log('OPEN')
      modalSharingGroupOpen.value = true
    }

    const renameModal = reactive({
      visible: false,
      row: {} as Album,
      async submit(item: Album) {
        try {
          renameModal.row = await model?.dispatch("album/update", item)
          renameModal.visible = false;
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    const importModal = reactive({
      visible: false,
      submit(items: { Id: number }[]) {
        try {
          model?.dispatch("picture/import", {
            albumId: router?.currentRoute.value.params.albumId,
            ids: items.map(e => e.Id)
          }).then(() => {
            model.state["album"]["reloadUI"] = true;
            importModal.visible = false;
          });
        } catch {
          Cloudfun.send("error", "匯入失敗");
        }
      }
    })

    onMounted(async () => {
      renameModal.row = await model?.dispatch("album/find", router?.currentRoute.value.params.albumId);
    })

    return {
      tooltipContent,
      modalOpen,
      renameModal,
      openModal,
      modalSharingGroupOpen,
      openSharingGroupModal,
      importModal,
      uploader: model?.state.batchUploader
    };
  },
  methods: {
    ...mapActions(["logout"]),
    onInput(event: any) {
      if (!event?.target?.files?.length) return;
      if (!(event.target.files instanceof FileList)) return;
      const list = event.target.files as FileList;
      for (let i = 0; i < list.length; i++) {
        this.uploader.push({
          id: uuid(),
          name: list[i].name,
          size: list[i].size,
          mimeType: list[i].type,
          blob: list[i],
          url: URL.createObjectURL(list[i]),
          action: `${process.env.VUE_APP_API}/api/Files`,
          parameters: { albumId: this.$route.params["albumId"] },
          status: 'pending',
        });
      }
    },
    signOut() {
      this.logout().finally(() => {
        this.$router.push("/login");
      })
    }
  }
});
