
/* eslint-disable */
import Cloudfun, { ref, defineComponent, Sorting, SortOrder, Condition, Operator } from "@cloudfun/core";
import DicomViewer from "@/components/dicom-viewer/Main.vue";
import SideImageList, { ImageListOptions, Image } from "@/components/side-image-list/Main.vue";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";
import {
  BellIcon,
  MenuIcon,
  XIcon,
  // ChevronDownIcon,
} from "@heroicons/vue/outline";
import DetailBar from "@/components/detail-bar/Main.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    DetailBar,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    DicomViewer,
    MenuIcon,
    XIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    Carousel,
    Slide,
    Navigation,
    SideImageList
  },
  setup() {
    const model = Cloudfun.current?.model;
    const route = Cloudfun.route;
    const router = Cloudfun.current?.policy?.router;
    const imageList = ref();
    const picture = ref<any>();
    const previousPicture = ref<any>();
    const nextPicture = ref<any>();
    const dicomViwer = ref();
    const history: any = ref([]);
    const infoData: any = ref([]);
    const filterData: any = ref({});
    const keyword: any = ref("");
    const imageListOptions: ImageListOptions = {
      promises: {
        query: (params) => {
          params.condition!.and("PictureRecord.ProjectId", Operator.Equal, route.query["projectId"]);
          if (filterData.value) {
            const { MinInferenceResult: min, MaxInferenceResult: max, Keyword, Sorting } = JSON.parse(route.query?.filter as any);
            if (!Number.isNaN(min)  && !Number.isNaN(max)) params.condition!.and("PictureRecord.InferenceResult", Operator.Equal, `${min}-${max}`);
            else if (!Number.isNaN(min)) params.condition!.and("PictureRecord.InferenceResult", Operator.GreaterThan, min);
            else if (!Number.isNaN(max)) params.condition!.and("PictureRecord.InferenceResult", Operator.LessThanOrEqual, max);
            if (Keyword) params.keyword = Keyword;
            if (Sorting) {
              // console.log("Sorting:", Sorting)
              // console.log("params.sortings:", params.sortings)
              params.sortings = [];
              params.sortings?.push(Sorting);
              // const current = params.sortings?.find(e => e.column === Sorting.column);
              // if (current) {
              //   current.order = Sorting.order;
              // } else {
              //   params.sortings?.push(Sorting);
              // }
            }
          } else { // default filter
            params.sortings = [new Sorting('Name', SortOrder.Ascending)];
          }
          params.pageSize = 100;
          return model!.dispatch("picture/query", params);
          
        }
      },

    }
    
    const showInfo = (data: any) => {
      infoData.value = data;
    }
    return {
      imageList,
      imageListOptions,
      picture,
      previousPicture,
      filterData,
      nextPicture,
      history,
      dicomViwer,
      infoData,
      showInfo,
      keyword
    };
  },
  // 載入時在 body tag 加上 class
  mounted() {
    // console.log(this.$route)
    document.body.classList.add("for-detail");
    this.changePicture(this.$route.query["pictureId"]);
    this.filterData.params = this.$route.query?.filter;
  },
  destroyed() { 
    document.body.classList.remove("for-detail");
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any)=>{
      vm.filterData.path = from.path;
    });
  },
  methods: {
    async changePicture(id: any) {
      await this.$model.dispatch("picture/find", id).then((data) => {
        // console.log(this.$route.query["projectId"]);
        var projectId: any = this.$route.query["projectId"]
        var InferenceResult = data.PictureRecord.find(e => e.ProjectId === parseInt(projectId))?.InferenceResult;
        this.picture = data;
        this.picture.InferenceResult = InferenceResult;
      })
      let filter = JSON.parse(this.$route.query?.filter as any),
          keyword = filter.Keyword || "",
          sortings = filter.Sorting || [new Sorting('Name', SortOrder.Ascending)];
      // this.history = await this.$model.dispatch("picture/pictureRecord", { key: this.picture.Id })
      try {
        this.previousPicture = await this.$model.dispatch("picture/previous", { keyword: keyword, sortings: sortings, id: this.picture.Id, projectId: this.$route.query["projectId"] });
      } catch {
        this.previousPicture = undefined;
      }
      try {
        this.nextPicture = await this.$model.dispatch("picture/next", { keyword: keyword, sortings: sortings, id: this.picture.Id, projectId: this.$route.query["projectId"] });
      } catch {
        this.nextPicture = undefined;
      }
    }
  },
});
