import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "3xl:container mx-auto px-24" }
const _hoisted_2 = { class: "grid grid-cols-5 gap-x-6 gap-y-10 mt-6 mb-6" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "mt-2 text-base font-normal text-tertiary truncate overflow-ellipsis overflow-hidden hover:cursor-pointer transition duration-300 ease-in-out hover:text-primary" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `image-list-${item.Id}-${index}`
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("a", {
              class: _normalizeClass(["square-box group", {'border border-success': _ctx.current === index }]),
              onClick: _withModifiers(($event: any) => (_ctx.onItemClick('body', item, index)), ["prevent"])
            }, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_ContentLoader, {
                    key: 0,
                    primaryColor: "#1B1B1B",
                    secondaryColor: "#212121",
                    width: "300",
                    height: "300",
                    class: "square-content object-center object-cover"
                  }))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: item.ThumbnailUri || _ctx.defaultImage,
                    class: "square-content hover:cursor-pointer object-center object-cover"
                  }, null, 8, _hoisted_5))
            ], 10, _hoisted_4),
            _createElementVNode("div", {
              class: "relative mt-4",
              onClick: ($event: any) => (_ctx.onItemClick('footer', item, index))
            }, [
              _createElementVNode("p", _hoisted_7, [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_ContentLoader, {
                      key: 0,
                      primaryColor: "#1B1B1B",
                      secondaryColor: "#212121",
                      width: "64",
                      height: "16px"
                    }))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      innerHTML: item.Name
                    }, null, 8, _hoisted_8))
              ])
            ], 8, _hoisted_6)
          ])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_v_pagination, {
        modelValue: _ctx.paginationConfig.currentPage,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.paginationConfig.currentPage) = $event)),
          _ctx.updateHandler
        ],
        pages: _ctx.paginationConfig.pages,
        "range-size": 1,
        "active-color": "#1B1B1B"
      }, null, 8, ["modelValue", "pages", "onUpdate:modelValue"])
    ])
  ]))
}