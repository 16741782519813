import { App } from "vue"
import { toDateString } from "xe-utils"

const helpers = {
  formatDate(date: string, format: string): string {
    return toDateString(date, format);
  },
  formatCurrency(number: number): string {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, '')
      const rest = formattedNumber.length % 3
      let currency = formattedNumber.substring(0, rest)
      const thousand = formattedNumber.substring(rest).match(/\d{3}/g)
      let separator

      if (thousand) {
        separator = rest ? '.' : ''
        currency += separator + thousand.join('.')
      }

      return currency
    } else {
      return ''
    }
  },
  areFiltersEqual(filter1, filter2) {
    return (
      filter1.value.MinInferenceResult === filter2.value.MinInferenceResult &&
      filter1.value.MaxInferenceResult === filter2.value.MaxInferenceResult &&
      filter1.value.Keyword === filter2.value.Keyword &&
      filter1.value.Sorting.column === filter2.value.Sorting.column &&
      filter1.value.Sorting.order === filter2.value.Sorting.order
    );
  }
  
  
}

const install = (app: App): void => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers as helper }
